<template>
  <form
    v-if="payload.formularfelder"
    class="constrain-1/2 form"
    @submit.prevent="submit"
  >
    <template v-for="field in payload.formularfelder" :key="field.id">
      <label
        :for="field.id + field.slug"
        :class="{ required: field.pflichtfeld.length === 1 }"
        v-text="field.title"
      />
      <textarea
        v-if="field.typ[0] === 'textarea'"
        :id="field.id + field.slug"
        v-model="data[field.slug]"
        :name="field.slug"
        :required="field.pflichtfeld.length === 1"
        class="mb-8 sm:mb-0"
      />
      <input
        v-else
        :id="field.id + field.slug"
        v-model="data[field.slug]"
        :name="field.slug"
        :type="field.typ[0]"
        :required="field.pflichtfeld.length === 1"
        class="mb-8 sm:mb-0"
      >
    </template>
    <button :disabled="sending" type="submit" class="hover-primary">
      <template v-if="!sending">
        Absenden
      </template>
      <template v-else>
        Sende...
      </template>
    </button>
    <div v-if="result" v-text="result" />
  </form>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const store = useStore();
    const sending = ref(false);
    const result = ref(null);
    const data = ref({ form: props.payload.title });

    const submit = async () => {
      sending.value = true;
      try {
        const toSend = { ...data.value };
        toSend.customeremail = props.payload.sendenanemail;

        // If there is no "first_name" field in Form
        if (!toSend.first_name) {
          let nameField = null;
          // check if there is a field that contains "name"
          Object.keys(data.value).forEach((key) => {
            if (key.toLowerCase().includes('name')) {
              nameField = key;
            }
          });

          // If there is one, set first_name to the same value
          if (nameField) {
            toSend.first_name = data.value[nameField];
          } else {
            // Else set it to "something"
            toSend.first_name = '[Unbekannt]';
          }
        }
        // If there is no E-Mail field in form
        if (!data.value.email) {
          Object.keys(data.value).forEach((key) => {
            const stripped = key
              .toLowerCase()
              .replace(' ', '').replace('-', '');
            if (stripped === 'email' || stripped === 'mail') {
              toSend.email = data.value[key];
            }
          });
          if (!toSend.email) {
            toSend.email = props.payload.sendenanemail;
          }
        }

        const response = await fetch(`${store.state.router.base}/ajax/forms/new_request/notify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(toSend),
        });
        const responseData = await response.json();
        if (responseData.type === 'success') {
          result.value = 'Vielen Dank für deine Nachricht.';
        } else {
          result.value = 'Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals.';
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err, err.message);
      }

      sending.value = false;
    };

    props.payload.formularfelder.forEach((f) => {
      data.value[f.slug] = '';
    });

    return {
      sending,
      result,
      data,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: grid;
  grid-template-columns: auto;
  column-gap: 1rem;

  @screen sm {
    grid-template-columns: auto 1fr;
    row-gap: 1rem;
  }
}
</style>
